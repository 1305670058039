import { endOfMonth, format, isToday, startOfMonth } from 'date-fns'
import { first } from 'lodash'
import React from 'react'

import {
    Badge,
    Box,
    Button,
    Chip,
    CloseButton,
    createStyles,
    Drawer,
    Flex,
    Group,
    rem,
    ScrollArea,
    SimpleGrid,
    Stack,
    Text,
} from '@mantine/core'
import { useElementSize, useViewportSize } from '@mantine/hooks'

import 'dayjs/locale/zh-cn'

import { MonthPicker } from '@mantine/dates'
import { showNotification } from '@mantine/notifications'

import { CanteenLayout } from '../components/CanteenLayout'
import { NotCanteenMenu } from '../components/NotCanteenMenu'
import { api } from '../utils/api'
import { useCalender, useIsLogin } from '../utils/hooks'
import type { NextPageWithLayout } from './_app'

const useStyles = createStyles((theme) => {
    const border = `1px solid ${
        theme.colorScheme === 'dark'
            ? theme.colors.dark[5]
            : theme.colors.gray[3]
    }`

    return {
        root: {
            userSelect: 'none',
            width: '100%',
            paddingLeft: theme.spacing.md,
            paddingRight: theme.spacing.md,
        },
        calendar: {
            width: '100%',
        },
        calendarContainer: {
            width: '100%',
            border: border,
            paddingLeft: theme.spacing.xs,
            paddingRight: theme.spacing.xs,
        },
        calendarWeekHeader: {
            flex: 1,
            marginLeft: `calc(${rem(theme.spacing.xs)} * -1)`,
            marginRight: `calc(${rem(theme.spacing.xs)} * -1)`,
            paddingLeft: theme.spacing.xs,
            paddingRight: theme.spacing.xs,
            borderBottom: border,
        },
        calendarWeekItem: {},
        calendarDays: {
            paddingTop: theme.spacing.xs,
            paddingBottom: theme.spacing.xs,
        },
        calendarDay: {
            fontSize: 14,
            cursor: 'pointer',
            borderRadius: theme.radius.xs,
        },
        calendarItem: {
            paddingTop: theme.spacing.xs,
            paddingBottom: theme.spacing.xs,
            flex: 1,
            textAlign: 'center',
            fontSize: 16,
        },
        calendarNextMonthDay: {
            color:
                theme.colorScheme === 'dark'
                    ? theme.colors.gray[7]
                    : theme.colors.gray[5],
        },
        calendarSelected: {
            backgroundColor: theme.colors.blue[5],
            color: theme.colors.gray[0],
        },
        calendarIsToDay: {
            backgroundColor:
                theme.colorScheme === 'dark'
                    ? theme.colors.dark[6]
                    : theme.colors.blue[0],
            color:
                theme.colorScheme === 'dark'
                    ? theme.colors.dark[0]
                    : theme.colors.blue[9],
        },
        calendarYear: {
            fontSize: 32,
            fontWeight: 900,
        },
        calendarMonth: {
            fontSize: 24,
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        calendarMenu: {
            padding: 2, // display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        calendarMenuItem: {
            fontSize: 12,
            lineHeight: 1.1,
            minWidth: 'max-content',
            color:
                theme.colorScheme === 'dark'
                    ? theme.colors.dark[5]
                    : theme.colors.gray[5],
        },
        calendarMenuItemSelected: {
            color: theme.colors.gray[4],
        },
        calendarMenuItemIsToday: {
            color: theme.colors.gray[4],
        },
        openLevel1: {
            height: '100%',
        },
        openLevel11: {
            height: '100%',
        },
        openLeve1Container: {
            border: border,
            position: 'absolute',
        },
    }
})

const Home: NextPageWithLayout = (_props) => {
    const { classes, cx } = useStyles()
    const {
        calendar,
        inRange,
        isSelected,
        toggle,
        viewing,
        setViewing,
        viewToday,
        weekTexts,
        viewSelectedMonth,
        viewRangeWeek,
        viewRangeMonth,
        clearSelected,
        viewPreviousMonth,
        viewNextMonth,
        selected,
    } = useCalender()

    const [isOpen, setIsOpen] = React.useState(false)
    const [isDateSelected, setIsDateSelected] = React.useState(false)
    const isLogin = useIsLogin()

    const {
        data: _fullMenuData = {
            mealTimes: [],
            menus: [],
            date: '',
        },
    } = api.canteenMenu.range.useQuery({
        year: viewing.getFullYear(),
        month: viewing.getMonth() + 1,
        day: viewing.getDate(),
        type: 'month',
    })

    const fullMenuData = React.useMemo(() => {
        const menus = _fullMenuData.menus.reduce<
            Record<string, (typeof _fullMenuData.menus)[0][]>
        >((acc, cur) => {
            const time = format(new Date(cur.date), 'yyyy-MM-dd')
            if (acc[time] === undefined) {
                acc[time] = [cur]
            } else {
                acc[time]?.push(cur)
            }
            return acc
        }, {})

        return {
            ..._fullMenuData,
            menus,
        }
    }, [_fullMenuData])

    const { width } = useViewportSize()
    const isMiniMobile = width >= 375

    type Menus = (typeof fullMenuData)['menus']
    type Menu = Menus[keyof Menus][0]

    function CalendarDay({
        day,
        data,
    }: {
        day: Date
        data: Menu[] | undefined
    }) {
        const isSelect = isSelected(day)
        const isNextMonth = !inRange(
            day,
            startOfMonth(viewing),
            endOfMonth(viewing),
        )

        const { data: _queryData } = api.canteenMenu.day.useQuery(
            {
                year: day.getFullYear(),
                month: day.getMonth() + 1,
                day: day.getDate(),
            },
            {
                enabled: data === undefined,
            },
        )

        const queryDataMenu = _queryData?.menus

        const menuData = React.useMemo(() => {
            return data ?? queryDataMenu ?? []
        }, [data, queryDataMenu])

        const _isToday = isToday(day)

        function MealTime({
            isToday,
            isSelected,
            data,
        }: {
            isToday: boolean
            isSelected: boolean
            data: Menu[]
        }) {
            return (
                <Box
                    className={cx(
                        classes.calendarMenu,
                        { [classes.calendarMenuItemSelected]: isSelected },
                        { [classes.calendarMenuItemIsToday]: isToday },
                    )}
                >
                    {!data && (
                        <Box className={classes.calendarMenuItem}>暂无餐时</Box>
                    )}
                    {data?.map(({ mealTime }) => {
                        return (
                            <Box
                                key={mealTime.id}
                                className={cx(
                                    classes.calendarMenuItem,
                                    {
                                        [classes.calendarMenuItemSelected]:
                                            isSelected,
                                    },
                                    {
                                        [classes.calendarMenuItemIsToday]:
                                            isToday,
                                    },
                                )}
                            >
                                {!isMiniMobile
                                    ? mealTime.shortName
                                    : mealTime.name}
                            </Box>
                        )
                    })}
                </Box>
            )
        }

        return (
            <Box
                className={cx(classes.calendarItem, classes.calendarDay, {
                    [classes.calendarNextMonthDay]: isNextMonth && !isOpen,
                    [classes.calendarIsToDay]: _isToday,
                    [classes.calendarSelected]: isSelect,
                })}
                key={`${day}`}
                onClick={() => {
                    if (isNextMonth) {
                        viewSelectedMonth(day)
                    }
                    if (isSelected(day)) {
                        clearSelected()
                        setIsOpen(false)
                        toggle(day, true)
                        viewRangeMonth(day)
                        return
                    } else {
                        if (!isLogin) {
                            showNotification({
                                message: '请先登录',
                                color: 'yellow',
                            })
                        } else {
                            setIsOpen(true)
                            clearSelected()
                            toggle(day, true)
                            viewRangeWeek(day)
                        }
                    }
                }}
            >
                <Box style={{ minWidth: 'max-content' }}>
                    {format(day, 'd')}
                    {isMiniMobile && '日'}
                </Box>
                <MealTime
                    isToday={_isToday}
                    isSelected={isSelect}
                    data={menuData}
                    key={`${day}-meal-time`}
                />
            </Box>
        )
    }

    function CalendarDays() {
        return (
            <Stack className={classes.calendarDays}>
                {calendar.map((month) => {
                    return month.map((week) => {
                        return (
                            <SimpleGrid
                                spacing={4}
                                cols={7}
                                key={`week-${week[0]}`}
                            >
                                {week.map((day) => {
                                    const time = format(day, 'yyyy-MM-dd')
                                    return (
                                        <CalendarDay
                                            data={fullMenuData.menus[time]}
                                            day={day}
                                            key={`${day}`}
                                        />
                                    )
                                })}
                            </SimpleGrid>
                        )
                    })
                })}
            </Stack>
        )
    }

    function OpenLevel1Select({ day }: { day: Date }) {
        const {
            data: dayData = {
                menus: [],
                mealTimes: [],
                date: '',
            },
        } = api.canteenMenu.day.useQuery({
            year: day.getFullYear(),
            month: day.getMonth() + 1,
            day: day.getDate(),
        })
        const { ref, width, height } = useElementSize()

        const isExpired = React.useMemo(() => {
            return dayData.mealTimes.every((menu) => {
                return menu.canReservation
            })
        }, [dayData])

        function Item({ menu }: { menu: (typeof dayData)['menus'][0] }) {
            return (
                <Flex px={'md'}>
                    <Stack
                        align={'center'}
                        justify={'center'}
                        style={{ minWidth: 'max-content' }}
                        spacing={2}
                    >
                        <Box>{menu.mealTime.name}</Box>
                        <Box
                            fz={12}
                            display={{
                                base: 'block',
                                xs: 'flex',
                            }}
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                display={'flex'}
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {menu.mealTime.startTime}
                            </Box>
                            <Box
                                display={{
                                    base: 'none',
                                    xs: 'flex',
                                }}
                            >
                                -
                            </Box>
                            <Box
                                display={'flex'}
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {menu.mealTime.endTime}
                            </Box>
                        </Box>
                    </Stack>
                    <Group
                        ml={'md'}
                        spacing={4}
                    >
                        {menu.meals.map((meal) => {
                            return (
                                <Badge key={`meal-${meal.id}`}>
                                    {meal.name}
                                </Badge>
                            )
                        })}
                    </Group>
                </Flex>
            )
        }

        function UserMealTime({
            data,
        }: {
            data: (typeof dayData)['menus'][0]
        }) {
            const {
                data: reservationData,
                isLoading,
                refetch,
            } = api.canteenReservation.select.useQuery({
                mealTimeId: data.mealTime.id,
                menuId: data.id,
            })
            const { mutateAsync: createReservation } =
                api.canteenReservation.create.useMutation({
                    onSuccess: () => {
                        void refetch()
                    },
                })
            const { mutateAsync: updateReservation } =
                api.canteenReservation.update.useMutation({
                    onSuccess: () => {
                        void refetch()
                    },
                })

            return (
                <Chip
                    pos={'relative'}
                    disabled={isLoading}
                    size={'md'}
                    checked={reservationData?.type === 'ORDERED'}
                    onChange={(checked) => {
                        if (!reservationData) {
                            void createReservation({
                                menuId: data.id,
                            })
                            return
                        }

                        if (checked) {
                            void updateReservation({
                                id: reservationData.id,
                                isCancel: false,
                            })
                        } else {
                            void updateReservation({
                                id: reservationData.id,
                                isCancel: true,
                            })
                        }
                    }}
                >
                    {data.mealTime.name}
                </Chip>
            )
        }

        return (
            <Box
                ref={ref}
                style={{
                    flex: 1,
                    position: 'relative',
                }}
            >
                <Box
                    className={classes.openLeve1Container}
                    style={{
                        width: width,
                        height: height,
                    }}
                >
                    <ScrollArea
                        h={'100%'}
                        w={'100%'}
                    >
                        <Flex p={'xs'}>
                            <Box
                                ml={'xs'}
                                fw={'bold'}
                            >
                                菜单
                            </Box>
                            <Box ml={'xs'}>{format(day, 'yyyy-MM-dd')}</Box>
                            <CloseButton
                                size={24}
                                onClick={() => {
                                    setIsOpen(false)
                                    clearSelected()
                                    viewRangeMonth(viewing)
                                }}
                                ml={'auto'}
                            />
                        </Flex>
                        {!dayData.menus.length && (
                            <Box>
                                <NotCanteenMenu />
                            </Box>
                        )}
                        {dayData.menus.length > 0 && (
                            <Stack>
                                {dayData.menus.map((menu) => {
                                    return (
                                        <Item
                                            key={`menu-${menu.id}`}
                                            menu={menu}
                                        />
                                    )
                                })}
                            </Stack>
                        )}

                        {dayData.menus.length > 0 && isExpired && (
                            <>
                                <Box
                                    pl={'xs'}
                                    ml={'xs'}
                                    fw={'bold'}
                                    mt={'md'}
                                >
                                    订餐
                                </Box>
                                <Flex
                                    gap='lg'
                                    justify='center'
                                    align='center'
                                    direction='row'
                                    wrap='wrap'
                                    mt={'md'}
                                    px={'md'}
                                    pb={'xl'}
                                >
                                    {dayData.menus.map((menu) => {
                                        return (
                                            <UserMealTime
                                                data={menu}
                                                key={`user-menu-time-${menu.id}`}
                                            />
                                        )
                                    })}
                                </Flex>
                            </>
                        )}
                    </ScrollArea>
                </Box>
            </Box>
        )
    }

    return (
        <Stack
            className={cx(classes.root, {
                [classes.openLevel1]: isOpen,
            })}
        >
            <Stack
                className={cx(classes.calendar, {
                    [classes.openLevel11]: isOpen,
                })}
            >
                {!isOpen && (
                    <>
                        <Flex
                            onClick={() => {
                                setIsDateSelected(true)
                            }}
                            onDoubleClick={viewToday}
                        >
                            <Text className={classes.calendarYear}>
                                {format(viewing, 'yyyy')}
                            </Text>
                            <Text
                                className={cx(classes.calendarMonth)}
                                ml={'auto'}
                                style={{ minWidth: 'max-content' }}
                            >
                                {format(viewing, 'LLLL')}
                            </Text>
                        </Flex>
                    </>
                )}
                <Stack
                    className={classes.calendarContainer}
                    spacing={0}
                >
                    {!isOpen && (
                        <SimpleGrid
                            spacing={4}
                            cols={7}
                            className={classes.calendarWeekHeader}
                        >
                            {weekTexts.map((day) => (
                                <Box
                                    className={cx(
                                        classes.calendarItem,
                                        classes.calendarWeekItem,
                                    )}
                                    key={`${day}`}
                                >
                                    {day}
                                </Box>
                            ))}
                        </SimpleGrid>
                    )}
                    <CalendarDays />
                </Stack>
                {!isOpen && (
                    <Flex justify={'center'}>
                        <Button
                            variant={'subtle'}
                            onClick={viewPreviousMonth}
                        >
                            上个月
                        </Button>
                        <Button
                            variant={'subtle'}
                            onClick={viewToday}
                        >
                            今天
                        </Button>
                        <Button
                            variant={'subtle'}
                            onClick={viewNextMonth}
                        >
                            下个月
                        </Button>
                    </Flex>
                )}
                {isOpen && isLogin && (
                    <OpenLevel1Select day={first(selected) || new Date()} />
                )}
                <Drawer
                    opened={isDateSelected}
                    onClose={() => {
                        setIsDateSelected(false)
                    }}
                    position={'bottom'}
                    lockScroll={true}
                    withCloseButton={false}
                    returnFocus={true}
                    withinPortal={true}
                    trapFocus={false}
                    size={'max-content'}
                >
                    <MonthPicker
                        value={viewing}
                        locale={'zh-cn'}
                        styles={{
                            calendar: {
                                width: '100%',
                            },
                            yearLevelGroup: {
                                width: '100%',
                            },
                            yearLevel: {
                                width: '100%',
                            },
                            calendarHeader: {
                                width: '100%',
                                maxWidth: '100%',
                            },
                            monthsList: {
                                width: '100%',
                            },
                            pickerControl: {
                                margin: '0 auto',
                            },
                            decadeLevelGroup: {
                                width: '100%',
                            },
                            decadeLevel: {
                                width: '100%',
                            },
                            yearsList: {
                                width: '100%',
                            },
                        }}
                        onChange={(date) => {
                            if (date) {
                                setViewing(date)
                            }
                        }}
                    />
                </Drawer>
            </Stack>
        </Stack>
    )
}

Home.getLayout = CanteenLayout

export default Home
