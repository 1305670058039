import React from 'react'

import { Container, createStyles, Text, Title } from '@mantine/core'

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
    },

    inner: {
        position: 'relative',
    },

    image: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 0,
        opacity: 0.75,
    },

    content: {
        paddingTop: 220,
        position: 'relative',
        zIndex: 1,

        [theme.fn.smallerThan('sm')]: {
            paddingTop: 120,
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 38,

        [theme.fn.smallerThan('sm')]: {
            fontSize: 32,
        },
    },

    description: {
        maxWidth: 540,
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: `clac(${theme.spacing.xl} * 1.5)`,
    },
}))

export function NotCanteenMenu() {
    const { classes } = useStyles()

    return (
        <Container className={classes.root}>
            <div className={classes.inner}>
                <div className={classes.content}>
                    <Title className={classes.title}>没有菜单</Title>
                    <Text
                        color='dimmed'
                        size='lg'
                        align='center'
                        className={classes.description}
                    >
                        选择的日期没有菜单，请联系管理员。
                    </Text>
                </div>
            </div>
        </Container>
    )
}
